/* @import url("https://fonts.googleapis.com/css2?family=Cormorant:ital,wght@0,300..700;1,300..700&family=Figtree:ital,wght@0,300..900;1,300..900&family=Heebo:wght@100..900&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: "ibradn", serif;
  src: url("/public/fonts/Ibrand.otf");
  
}
.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

/* .inter-medium {
  font-family: "Inter", serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
} */
/* FONTS */

body {
  padding: 0;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
  background-color: #F0F1FA;
}
.blur-top {
  -webkit-mask-image: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    black 50%
  );
  filter: blur(1px);
}
.img {
  transform: translate3d(100px);
}

/* NAVBAR */
.navbar {
  z-index: 101;
}

/* HOMEPAGE */
.main-screen {
  max-width: 100% !important;
}

.mouse {
  animation: heightAnim 1s infinite;
}

@keyframes heightAnim {
  0% {
    margin-top: 4px;
  }
  50% {
    margin-top: 6px;
  }
  100% {
    margin-top: 4px;
  }
}

.dots {
  animation: blink 1s infinite;
  background: #fff;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.card-container {
  width: 100%;
  height: 300vh;
}

.active-slide {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.35;
  z-index: 0; /* Daha iyi bir katman sıralaması */
}

.active-slide img {
  position: absolute;
  filter: blur(50px);
  transform: scale(1.125);
}

.slider {
  position: sticky;
  top: 0;
  height: 100vh;
  transform-style: preserve-3d;
  perspective: 900px;
  overflow: hidden;
}
.slide {
  position: absolute;
  width: 400px;
  height: 500px;
  overflow: hidden;
}

.slide-copy {
  /* text-transform: uppercase;
  text-align: center;
  font-size: 13px;
  font-weight: bolder;
  line-height: 150%;
  color: var(--color-accent); */
}

.slide-copy p#index {
  font-size: 11px;
  font-weight: 400;
  margin-bottom: 0.75em;
}

#slide-1 {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translateX(-50%) translateY(-50%) translateZ(-7500px);
  opacity: 0;
}
#slide-2 {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateX(-50%) translateY(-50%) translateZ(-5000px);
  opacity: 0;
}
#slide-3 {
  position: absolute;
  top: 50%;
  left: 70%;
  transform: translateX(-50%) translateY(-50%) translateZ(-2500px);
  opacity: 0;
}
#slide-4 {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translateX(-50%) translateY(-50%) translateZ(0px);
  opacity: 0;
}

/* SLIDER-BUTTONS */
.swiper-button-prev::after {
  color: transparent;
  display: none;
}

.swiper-button-next::after {
  color: transparent;
  display: none;
}

/* DEVICE */
.card-device-img-left {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
}

/* StickySections.css */
.content--sticky {
  width: 100%;
  position: sticky;
  top: var(--offset);
  padding: 2rem;
}

/* WORK PAGE */
.work-container:hover .work-item {
  opacity: 0.45;
  transition: all 0.3s ease;
}

.work-container:hover .work-item:hover {
  opacity: 1;
}

/* LOADING */
.loading {
  animation: infinityLight 1.3s infinite;
}

@keyframes infinityLight {
  0% {
    opacity: .5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .5;
  }
}
/* offer page */
.kvkk-modal::-webkit-scrollbar {
  display: none;
}
.kvkk-modal{
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* MOUSE SLIDER */
@media (max-width: 769px) {
  .mouse-slider-container-pc {
    display: none !important;
  }
}
@media (min-width: 769px) {
  .mouse-slider-container-mobile {
    display: none !important;
  }
}

/* Ball Pool */
.falling-text-container {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
  text-align: center;
  padding-top: 2em;
}

.falling-text-target {
  display: inline-block;
}

.word {
  display: inline-block;
  margin: 0 2px;
  user-select: none;
}

.highlighted {
  color: cyan;
  font-weight: bold;
}

.falling-text-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
}

/* ScrollBar */
.cls-1 {
  fill: none;
  stroke: url(#gradient1);
  stroke-miterlimit: 10;
  stroke-width: 5px;
  /* filter: drop-shadow(0px 0px 30px rgba(184, 40, 40, 0.8)); */
}

.st0 {
  fill: none;
  stroke: url(#gradient1);
  stroke-miterlimit: 10;
  stroke-width: 5px;
}


@media (max-width: 768px) {
  .cls-1 {
    stroke-width: 7px;
  }
}
